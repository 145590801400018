.ck-editor__editable_inline {
  min-height: 250px;
}
/*The followin css add to fix the hyperlink problem */
body {
  /* We need to assaign this CSS Custom property to the body instead of :root, because of CSS Specificity and codepen stylesheet placement before loaded CKE5 content. */
  --ck-z-default: 100;
  --ck-z-modal: calc(var(--ck-z-default) + 999);
}

/* We need to overwrite default Materialize list appearance. 
 * See: https://github.com/ckeditor/ckeditor5/issues/1672. 
 */
.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}

.ck.ck-content ul {
  /* Default user agent stylesheet. */
  padding-left: 40px;
}

/* We need to overwrite default Materialize input appearance. 
  * See: https://github.com/Dogfalo/materialize/blob/v1-dev/sass/components/forms/_input-fields.scss#L10-L40 
  */
.ck input.ck-input.ck-input-text {
  box-shadow: var(--ck-inner-shadow), 0 0;
  background: var(--ck-color-input-background);
  border: 1px solid var(--ck-color-input-border);
  padding: var(--ck-spacing-extra-tiny) var(--ck-spacing-medium);
  transition-property: box-shadow, border;
  transition: 0.2s ease-in-out;

  height: inherit;
  width: inherit;
  font-size: inherit;
  margin: 0;
  box-sizing: border-box;
}

.ck input.ck-input.ck-input-text:focus {
  border: var(--ck-focus-ring);
  box-shadow: var(--ck-focus-outer-shadow), var(--ck-inner-shadow);
}
